<template>
  <template v-if="!appLoading">
    <SiteToasts />
    <Component
      :is="$route.meta?.layout ?? 'DefaultLayout'"
      v-if="$route.name"
    >
      <router-view  v-slot="{ Component }">
        <transition name="page" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </Component>
  </template>
  <div
    v-else
    class="flex h-screen w-screen items-center justify-center"
  >
    <SlLoading
      size="x-large"
      text=""
    />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
// @ts-expect-error
import { SlLoading } from '@slcyber/component-library';
import AuthLayout from './layouts/AuthLayout.vue';
import DefaultLayout from './layouts/DefaultLayout.vue';
import SiteToasts from './components/SiteToasts.vue'
import { useApplicationStore } from './stores/application';

export default {
  components: {
    SiteToasts,
    AuthLayout,
    DefaultLayout,
    SlLoading,
  },
  computed: {
    ...mapState(useApplicationStore, ['appLoading']),
  },
}
</script>

<style scoped lang="scss">

.page-enter-from,
.page-leave-to
{
  opacity: 0;
  max-height: 170px;
}

.page-enter-to,
.page-leave-from
{
  opacity: 1;
  max-height: 1200px;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.2s ease-in-out;
}
</style>
