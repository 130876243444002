<template>
  <div class="flex h-screen w-screen bg-white text-sl-black-600 max-sm:flex-col">
    <div class="h-full py-20 sm:w-1/2">
      <div class="flex h-full flex-col items-start justify-center gap-4 pl-8 md:pl-16 lg:pl-28 xl:pl-40 2xl:pl-56 min-[1800px]:pl-72">
        <div class="pb-[43px]">
          <img
            src="../assets/images/searchlight_logo_dark.svg"
            alt="Searchlight Cyber Logo"
            class="inline-block h-14 border-r border-sl-black-800 pr-[10px] align-middle"
          >
          <img
            src="../assets/images/assetnote_logo.svg"
            alt="Assetnote Logo"
            class="inline-block h-[38px] pl-[10px] align-middle"
          >
        </div>
        <div class="w-full">
          <slot />
        </div>
      </div>
    </div>
    <img
      id="login-image"
      class="h-full sm:w-1/2"
      src="../assets/images/login-background.webp"
    >
    <div class="absolute bottom-4 right-4">
      <img
        src="../assets/images/Accreditation_Logos_x4-01.png"
        class="h-10 xl:h-16"
        alt="
          Searchlight Cyber ISO-27001 Cyber Essentials AICPA SOC
          Crown Commercial Service Supplier Certification
        "
      >
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'AuthLayout',
};
</script>

<style lang="scss" scoped>
  #login-image {
    object-fit: cover;
    @media (min-width: 640px) {
      clip-path: polygon(8% 0, 100% 0, 100% 100%, 0 100%);
    }
    @media (max-width: 640px) {
      clip-path: polygon(0 8%, 100% 0, 100% 100%, 0% 100%);
    }
  }
h1 {
  @apply font-[BarlowCondensed-SemiBold];
}
h2 {
  @apply font-[BarlowCondensed-Medium];
}

@font-face {
  font-family: "BarlowCondensed-Medium";
  src: url("../assets/fonts/BarlowCondensed-Medium.ttf");
}

@font-face {
  font-family: "BarlowCondensed-SemiBold";
  src: url("../assets/fonts/BarlowCondensed-SemiBold.ttf");
}
</style>
