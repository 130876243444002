/**
 * Load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import type { App } from 'vue';
import axios, { AxiosError, type AxiosInstance, type AxiosResponse } from 'axios';
import { authStore } from '../stores/auth';
import { endpoints } from './endpoints';

export default {
  install: (app: App) => {
    const a = axios.create({
      baseURL: '/api/v1',
      headers: {
        common: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      },
      withCredentials: true,
    });
    a.interceptors.response.use(
      function response(res: AxiosResponse) {
        return res;
      },
      function error(err: AxiosError<Record<string, string>>) {
        const store = authStore();
        if (
          err.response?.status === 401 &&
          err.response?.data?.message === 'Unauthenticated.' &&
          err.response.config.url !== endpoints.auth.user
        ) {
          store.logout(false);
        } else {
          return Promise.reject(err);
        }
        return err;
      }
    )
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$axios = a
  },
};

declare module 'vue' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance
  }
}
