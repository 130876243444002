import type { RouteLocationNormalized } from "vue-router";
import { authStore } from '../../stores/auth';

export default function authenticated(to: RouteLocationNormalized) {
  const store = authStore();
  if (!store.user) {
    store.preserved_route = to;
    return { name: 'login' };
  }
  return true;
}
