// @ts-expect-error
import library from '@slcyber/component-library/icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/**
 * FontAwesome Solid Library
 *
 * As our use of Solid is very limited we're making explicit calls to import icons we want to use.
 */
import {
  faDownload, faPrint, faXmark, faBrakeWarning, faGrid, faRightFromBracket,
} from '@fortawesome/pro-solid-svg-icons';
import type { App } from 'vue';

library.add(faDownload, faPrint, faXmark, faBrakeWarning, faGrid, faRightFromBracket);

export default {
  install: (app: App) => {
    app.component('FontAwesomeIcon', FontAwesomeIcon);
  },
};
