import type { RouteLocationNamedRaw, RouteLocationNormalized } from "vue-router";
import { authStore } from '../../stores/auth';

export default function appRedirection(
  to: RouteLocationNormalized | null | undefined,
  from: RouteLocationNormalized
): boolean | RouteLocationNamedRaw {
  const activeRoutes = [
    'login',
    'two-factor-challenge',
  ];
  const store = authStore();
  if (
    typeof from.name === 'string' &&
    activeRoutes.includes(from.name) &&
    !store.preserved_route &&
    store.applications.length
  ) {
    return { name: 'app-redirect' };
  }
  return true;
}