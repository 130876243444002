import type { App } from "vue";

export const endpoints = {
  auth: {
    change_password: '/auth/change-password',
    login: '/auth/login',
    logout: '/auth/logout',
    reset: '/auth/forgot-password',
    reset_password: '/auth/reset-password',
    forgot_password: '/auth/forgot-password',
    user: '/user',
    saml_login: '/api/v1/auth/saml-login',
    sso: {
      check: '/auth/sso/check',
    },
    two_factor: {
      index: '/auth/two-factor',
      recovery_codes: '/auth/two-factor/recovery-codes',
      challenge: '/auth/two-factor/challenge',
      confirm: '/auth/two-factor/confirm',
      qr_code: '/auth/two-factor/qrcode',
    },
  },
  applications: '/app-switcher',
  users: {
    index: '/users'
  },
}

export default {
  install: (app: App) => {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$endpoints = endpoints;
  },
};

declare module 'vue' {
  interface ComponentCustomProperties {
    $endpoints: Record<string, any>
  }
}
