import { defineStore } from 'pinia';
import type { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import type { AxiosInstance } from 'axios';
import type { AuthStore } from '../types';
// eslint-disable-next-line import/no-cycle
import { useApplicationStore } from './application';

export const authStore = defineStore('auth', {
  state: (): AuthStore => ({
    user: null,
    organisations: [],
    applications: [],
    two_factor_authenticated: false,
    preserved_route: null,
    loggedIn: false,
  }),
  actions: {
    async fetchUser() {
      return this.$axios.get(this.$endpoints.auth.user).then((userResponse) => {
        this.user = userResponse.data;
        if (this.user?.links?.organisations) {
          return Promise.all(
            this.user.links.organisations.map((link) => this.$axios.get(link))
          ).then((orgResponses) => {
            this.organisations = orgResponses.map((orgResponse) => orgResponse.data);
          }).then(() => {
            return this.$axios.get(this.$endpoints.applications)
              .then((applications) => {
                this.applications = applications.data.data;
                this.loggedIn = true;
              })
          });
        }
        return userResponse.data;
      }).catch(() => {
        const allowedRoutes = [
          'change-password',
          'forgot-password',
        ];

        const currentRoute = this.$router.resolve(window.location.pathname);
        const allowed = allowedRoutes.includes(currentRoute.name?.toString() ?? '');

        if (!allowed) {
          this.logout(false);
        }
      })
    },
    logout(request = true) {
      this.$reset();
      if (request) {
        const appStore = useApplicationStore();
        appStore.appLoading = true;
        window.location.href = '/api/v1/auth/saml-logout';
      } else {
        this.$router.push({ name: 'login' });
      }
    }
  },
  persist: {
    paths: ['two_factor_authenticated', 'preserved_route']
  },
  getters: {
    mandatory2fa: (state) => {
      return state.organisations.some(
        (organisation) => organisation.mandatory_two_factor
      );
    },
    samlEnabled: (state) => {
      return state.organisations.length && state.organisations.some(
        (organisation) => organisation.saml_enabled
      );
    },
  }
});

declare module 'pinia' {
  export interface PiniaCustomProperties {
    '$endpoints': Record<string, any>
    '$axios': AxiosInstance
    '$router': Router,
    '$route': RouteLocationNormalizedLoaded,
  }
}
