import type { App } from "vue";
import * as Sentry from '@sentry/vue';
import router from '../router';

export default {
  install: (app: App) => {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to
      // change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session,
      // change the sample rate to 100% when sampling sessions where errors occur.
    });
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$Sentry = Sentry;
  },
};
