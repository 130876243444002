<template>
  <div
    class="toast small-body mb-4 rounded-md p-3 !text-white"
    :class="styles"
    data-testid="toast-message"
  >
    <div class="flex items-center justify-between gap-3">
      <div class="flex items-center gap-3">
        <font-awesome-icon
          v-if="type === 'warning'"
          :icon="['fas', 'exclamation-triangle']"
          :fixed-width="true"
          data-testid="toast-message-warning-icon"
        />
        <font-awesome-icon
          v-else
          :icon="['fas', 'check']"
          :fixed-width="true"
          data-testid="toast-message-success-icon"
        />
        <p>{{ message }}</p>
      </div>
      <font-awesome-icon
        :icon="['fas', 'times']"
        :fixed-width="true"
        class="ml-3 cursor-pointer justify-self-end"
        data-testid="toast-message-close-icon"
        @click.prevent="remove"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ToastNotification',
  props: {
    type: {
      type: String,
      required: false,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  computed: {
    styles() {
      switch (this.type) {
        case 'success':
          return 'bg-sl-green-600';
        case 'warning':
          return 'bg-sl-red-700';
        default:
          return 'bg-sl-orange-700';
      }
    },
  },
  methods: {
    remove() {
      this.$emit('close');
    },
  },
};
</script>
