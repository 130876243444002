import type { RouteLocationNamedRaw, RouteLocationNormalized } from 'vue-router';
import { authStore } from '../../stores/auth';

export default function redirectIfAuthenticated(
  to: RouteLocationNormalized
): boolean | RouteLocationNamedRaw {
  const store = authStore();
  let redirect = false;
  const twoFactorAuthenticated = !!store.user?.two_factor_enabled && store.two_factor_authenticated;
  switch (to.name) {
    case 'login':
      redirect = !!store.user;
      break;
    case 'two-factor-setup':
      redirect = !store.mandatory2fa ||
        (store.mandatory2fa && !!store.user?.two_factor_enabled);
      break;
    case 'two-factor-challenge':
      redirect = twoFactorAuthenticated;
      break;
    // Stryker disable next-line ConditionalExpression
    default:
      break;
  }

  if (redirect) {
    const hasAppAccess = store.applications.find((app) => app.has_access.user) !== undefined;
    if (hasAppAccess) {
      return { name: 'app-redirect' }
    }
    return { name: 'settings' };
  }
  return true;
}
