import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import './index.scss';
import axios from './plugins/axios';
import Sentry from "./plugins/sentry";
// eslint-disable-next-line import/no-named-as-default
import endpoints from './plugins/endpoints';
import i18n from './plugins/i18n';
import router from './router';
import toast from './plugins/toast';

import App from './App.vue'
import fontawesome from './plugins/fontawesome';

const app = createApp(App)
const pinia = createPinia();

// Directives
app.directive('click-away', {
  beforeMount: (elem, binding) => {
    // eslint-disable-next-line no-param-reassign
    elem.clickAwayEvent = function clickAwayEvent(event: Event) {
      if (!(elem === event.target || elem.contains(event.target))) {
        binding.value(event, elem);
      }
    };
    document.body.addEventListener(
      'click',
      elem.clickAwayEvent,
    );
  },
  unmounted: (elem) => {
    document.body.removeEventListener(
      'click',
      elem.clickAwayEvent,
    );
  },
});

app.use(i18n)
  .use(axios)
  .use(Sentry)
  .use(endpoints)
  .use(router)
  .use(toast)
  .use(pinia.use(piniaPluginPersistedstate)
    .use(({ store }) => {
      // eslint-disable-next-line no-param-reassign
      store.$axios = app.config.globalProperties.$axios;
      // eslint-disable-next-line no-param-reassign
      store.$endpoints = app.config.globalProperties.$endpoints;
      // eslint-disable-next-line no-param-reassign
      store.$router = app.config.globalProperties.$router;
      // eslint-disable-next-line no-param-reassign
      store.$route = app.config.globalProperties.$route;
    }))
  .use(fontawesome)

app.mount('#app')
