import type { App } from 'vue';
import { toastStore } from '../stores/toast';
import type { ToastType } from '../types';

export default {
  install: (app: App) => {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$toast = (message: string, type: ToastType, ms: number = 4500) => {
      const store = toastStore();
      store.createToast({ message, type, ms })
    }
  },
};

interface Toast {
    (message: string, type: ToastType, ms?: number): void;
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $toast: Toast
  }
}
