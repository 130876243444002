import type { RouteLocationNormalized } from 'vue-router';
import { authStore } from '../../stores/auth';

export default function twoFactorAuthenticated(to: RouteLocationNormalized) {
  const store = authStore();
  if (
    store.user &&
    !store.user.two_factor_enabled &&
    store.mandatory2fa
  ) {
    store.preserved_route = to;
    return { name: 'two-factor-setup' };
  }
  if (
    store.user &&
    store.user.two_factor_enabled &&
    !store.two_factor_authenticated
  ) {
    store.preserved_route = to;
    return { name: 'two-factor-challenge' };
  }
  return true;
}
