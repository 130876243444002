import { defineStore } from 'pinia';
import type { Router } from 'vue-router';
import type { AxiosInstance } from 'axios';
import type { Toast, ToastStore } from '../types';

export const toastStore = defineStore('toast', {
  state: (): ToastStore => ({
    toasts: []
  }),
  actions: {
    createToast(toast: Toast) {
      this.toasts.push(toast);
      setTimeout(this.clearToast, toast.ms ?? 4500);
    },
    removeToast(index: number) {
      this.toasts.splice(index, 1);
    },
    clearToast() {
      this.toasts.shift();
    }
  },
});

declare module 'pinia' {
  export interface PiniaCustomProperties {
    '$endpoints': Record<string, any>
    '$axios': AxiosInstance
    '$router': Router
  }
}
