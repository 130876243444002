<template>
  <div class="h-screen w-screen bg-white text-sl-black-600">
    <div class="size-full">
      <NavigationBar />
      <slot class="h-content"/>
    </div>
  </div>
</template>

<script lang="ts">
import NavigationBar from '../components/NavigationBar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    NavigationBar,
  },
};
</script>

<style lang="scss">
.h-content {
  height: calc(100% - 3rem);
}
</style>
