import { createRouter, createWebHistory } from 'vue-router';
import {
  Authenticated,
  TwoFactorAuthenticated,
  RoutePreservation,
  RedirectIfAuthenticated,
  AppRedirection
} from './middleware';
import { useApplicationStore } from '../stores/application';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: 'index',
      path: '/',
      redirect: { name: 'app-redirect' },
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('../views/LoginPage.vue'),
      meta: {
        layout: 'AuthLayout',
        middleware: [RedirectIfAuthenticated]
      }
    },
    {
      name: 'forgot-password',
      path: '/forgot-password',
      component: () => import('../views/ForgotPasswordPage.vue'),
      meta: {
        layout: 'AuthLayout',
        middleware: [RedirectIfAuthenticated]
      }
    },
    {
      name: 'change-password',
      path: '/change-password',
      component: () => import('../views/ChangePasswordPage.vue'),
      meta: {
        layout: 'AuthLayout',
        middleware: [RedirectIfAuthenticated]
      }
    },
    {
      name: 'two-factor-setup',
      path: '/two-factor-setup',
      component: () => import('../views/TwoFactorSetupPage.vue'),
      meta: {
        layout: 'AuthLayout',
        middleware: [Authenticated, RedirectIfAuthenticated]
      }
    },
    {
      name: 'two-factor-challenge',
      path: '/two-factor-challenge',
      component: () => import('../views/TwoFactorChallengePage.vue'),
      meta: {
        layout: 'AuthLayout',
        middleware: [Authenticated, RedirectIfAuthenticated]
      }
    },
    {
      name: 'saml-login',
      path: '/saml/login',
      component: () => import('../views/SamlLoginPage.vue'),
      meta: {
        middleware: [Authenticated, TwoFactorAuthenticated, RoutePreservation]
      }
    },
    {
      name: 'app-redirect',
      path: '/app-redirect',
      component: () => import('../views/AppRedirectPage.vue'),
      meta: {
        middleware: [Authenticated, TwoFactorAuthenticated, RoutePreservation]
      }
    },
    {
      name: 'settings',
      path: '/settings',
      component: () => import('../views/SettingsPage.vue'),
      meta: {
        middleware: [Authenticated, TwoFactorAuthenticated, RoutePreservation, AppRedirection]
      }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('../views/NotFound.vue'),
    },
  ]
})

// Middleware handler
router.beforeEach(async (to, from) => {
  const appStore = useApplicationStore();
  if (
    !from.name &&
    !appStore.appLoading &&
    !appStore.appLoadComplete
  ) {
    await appStore.appLoad();
  }

  if (to.meta?.middleware?.length) {
    for (let i = 0; i < to.meta.middleware.length; i++) {
      const middleware = to.meta.middleware[i];
      const next = middleware(to, from);
      if (next !== true) {
        return next;
      }
    }
  }
  return true;
})

export default router

declare module 'vue-router' {
  interface RouteMeta {
    middleware?: Array<Function>,
    layout?: string,
  }
}
