<template>
  <div class="fixed right-0 top-0 z-50 mr-4 mt-4">
    <TransitionGroup
      name="fade"
      tag="div"
    >
      <ToastNotification
        v-for="(toast, index) in toastStore.toasts"
        :key="index + 1"
        :message="toast.message"
        :type="toast.type"
        @remove-toast="removeToast(index)"
      />
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import ToastNotification from './ToastNotification.vue';
import { toastStore } from '../stores/toast';

export default {
  name: 'SiteToasts',
  components: {
    ToastNotification,
  },
  computed: {
    ...mapStores(toastStore)
  },
  methods: {
    removeToast(index: number) {
      this.toastStore.removeToast(index);
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
}

.fade-enter-from,
.fade-leave-to {
  transition: opacity 0.5s ease;
  opacity: 0;
}
</style>
