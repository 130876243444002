<template>
  <div class="flex h-12 items-center justify-between bg-sl-black-600">
    <div
      v-click-away="clickAway"
      data-testid="app-switcher-toggle"
      class="flex h-full cursor-pointer items-center gap-5 px-4 hover:bg-sl-black-400"
      @click="toggleAppSwitcher()"
    >
      <font-awesome-icon
        class="size-5.75 text-white"
        :icon="['fas', 'grid-round']"
      />
      <img
        class="h-8"
        src="../assets/images/searchlight_logo_light.svg"
        alt="Searchlight Cyber Logo"
      >
      <SlAppSwitcher
        v-if="showAppSwitcher"
        class="absolute left-1.25 top-13.5"
        :active-application="null"
        :applications="authStore.applications"
        :username="authStore?.user?.first_name"
        logout-url="/api/v1/auth/saml-logout"
        :portal="portalUrl"
        :up-sell="false"
        @click.stop
      />
    </div>
    <button
      class="flex cursor-pointer items-center gap-2 px-4 text-sm font-semibold text-white hover:text-sl-orange-700"
      @click.prevent="logout"
    >
      <font-awesome-icon :icon="['fas', 'right-from-bracket']" />
      <p>{{ $t('authentication.logout') }}</p>
    </button>
  </div>
</template>

<script lang="ts">
// @ts-expect-error
import { SlAppSwitcher } from '@slcyber/component-library';
import { mapStores } from 'pinia'
import { authStore } from '../stores/auth';

export default {
  name: 'NavigationBar',
  components: {
    SlAppSwitcher,
  },
  data() {
    return {
      showAppSwitcher: false,
      portalUrl: window.location.origin,
    };
  },
  computed: {
    ...mapStores(authStore)
  },
  methods: {
    logout() {
      this.authStore.logout();
    },
    toggleAppSwitcher() {
      this.showAppSwitcher = !this.showAppSwitcher;
    },
    clickAway() {
      this.showAppSwitcher = false;
    },
  },
};
</script>
