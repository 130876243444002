import { authStore } from '../../stores/auth';

export default function routePreservation() {
  const store = authStore();
  if (store.preserved_route) {
    const route = store.preserved_route;
    store.preserved_route = null;
    return route;
  }
  return true;
}
