import { createI18n } from 'vue-i18n';
import * as en from '../../locales/en';

export default createI18n({
  legacy: false,
  locale: import.meta.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: import.meta.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  availableLocales: ['en'],
  messages: {
    en,
  },
});
